<template>
  <v-app>
    <section class="login-section">
      <div class="login-blk">
        <div class="screen-lft">
          <div class="logo">
            <a href="#">
              <img src="@/assets/images/cp-logo-new.png" alt="" />
            </a>
          </div>
        </div>
        <div class="screen-rgt">
          <div class="screen-outer">
            <div class="screen-title">
              <h2 class="font-18px">
                {{ $t("page_forgot_password_title") }}
              </h2>
              <div class="font-14px">
                ご登録のメールアドレスに <br />
                パスワード再設定用のメールを送信します。
              </div>
            </div>
            <div class="screen-form">
              <form>
                <v-text-field
                  :placeholder="$t('page_forgot_password_email_placeholder')"
                  filled
                  rounded
                  dense
                  class="input-text"
                  v-model="email"
                ></v-text-field>

                <div class="marin-bottom-field"></div>
                <v-btn
                  block
                  class="text-capitalize btn-submit"
                  large
                  color="primary"
                  :disabled="!isValid"
                  @click="submit"
                >
                  {{ $t("page_forgot_password_submit_button") }}
                </v-btn>
                <div class="marin-bottom-field"></div>
                <div class="forget-blk">
                  <span class="forgot-text font-Noto-Sans">
                    <router-link :to="{ name: 'Login' }">
                      {{ $t("page_forgot_password_login_redirect_text") }}
                    </router-link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="copyright-blk">
      <p>© 2017 - {{ copyrightyear }} redish Co., Ltd.</p>
    </div>
  </v-app>
</template>

<script>
import dayjs from "@/plugins/dayjs";
export default {
  name: "ForgotPassword",
  data() {
    return {
      email: null,
      isValid: true,
      copyrightyear: null,
      rules: {
        required: v => !!v || this.$t("required"),
        email: [
          v =>
            !!v ||
            this.$t("page_forgot_password_email_required_validation_text")
        ]
      }
    };
  },
  mounted() {
    this.copyrightyear = dayjs().format("YYYY");
  },
  methods: {
    submit() {
      let email = this.email;
      let reset_url = window.location.origin + "/reset-password/";
      this.$store.dispatch("AUTH_FORGOT", { email, reset_url }).then(
        response => {
          if (response.status >= 200 && response.status <= 299) {
            this.email = "";
            this.$store.dispatch("ALERT", {
              show: true,
              text: this.$t("page_forgot_password_success_text")
            });
          } else {
            this.$store.dispatch("ALERT", {
              show: true,
              text: this.$t("page_forgot_password_error")
            });
          }
        },
        error => {
          console.error(error);
          this.$store.dispatch("ALERT", {
            show: true,
            text: this.$t("page_forgot_password_error")
          });
        }
      );
    }
  }
};
</script>
<style lang="scss" src="./_styles.scss"></style>
